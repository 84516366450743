/*============================================================================================= 
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Iqbal Wahyudi Pratama - iqbalwahyudip.com
==============================================================================================*/
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,700,800);
@import url('https://fonts.googleapis.com/css?family=Hind:300,400,500,700');
@import "../css/reset";
@import "sass-lib";

body {  
    color: #333;
    font-size: 12px;
    font-family: 'Hind', sans-serif;
    position: relative;
    overflow-x: hidden;
}
figure{
    margin: 0; padding: 0;
    img{vertical-align: top;}
}
img{ border:0; max-width:100%; height:auto; }
p {
    margin: 1px 0 15px;
    line-height: 20px;
}
input, textarea, select {
    font-size: 12px;
    border: none;
    border: 1px solid #D8D8D8;
    padding: 10px;
    margin: 0;
    color: #666;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-family: 'Hind', sans-serif;
}
button{font-family: 'Hind', sans-serif;}
textarea{resize: none;}
input[type=checkbox], input[type=radio] {
    border-top-style: none;
    border-right-style: none;
    border-bottom-style: none;
    border-left-style: none;
}
h1 { font-size: 32px; }
h2 { font-size: 40px; font-weight: 300;}
h3 { font-size: 28px; font-weight: 700; line-height: 32px;}
h4 { font-size: 20px; } 
h5 { font-size: 18px; font-weight: 700;} 
h6 { font-size: 14px; }

h5.marg-btm{
    margin-bottom: 30px;
    &.blue {color: #023770;} 
}

/* structure 
----------------------------------------------------------------------------------------------*/
section { position: relative; }
.wrapper {width: 1170px;margin: 0 auto;}
.wrap-inner{width: 830px; margin: 0 auto;}

/* global
----------------------------------------------------------------------------------------------*/
.after_clear:after,.wrapper:after,section:after{clear:both;display: block;content:" "}
.before_clear:before{clear:both;display: block;content:" "}
.a-left{text-align: left}
.a-right{text-align: right}
.a-center{text-align: center}
.italic { font-style: italic; }
.bold { font-weight: bold; }
.regular { font-weight: lighter; }
.fl { float: left; }
.fr { float: right; }
.clear { clear: both; }

.box-radio-button{
    padding-top: 13px;
    .radio-custom{
        float: left; margin-right: 15px; margin-bottom: 0; line-height: 20px;
        &:last-child{margin-right: 0;}
    }
    &.box-radio-jk{padding-top: 0;}
}
.radio-custom{
    position: relative; padding-left:20px; margin: 0; cursor: pointer; height: 40px;
    input[type=radio]{
        background: url(../images/material/ic_radio.png) no-repeat; display: inline-block; vertical-align: middle; margin:0; padding: 0; width:16px; height:16px; position:absolute; top:0; left:0; cursor: pointer;
        &:checked{background-position: right center;}
    }
    &.radio-jk{
        padding-left: 50px; padding-top: 13px;
        input[type=radio]{width: 40px; height: 40px;}
        &.rmale{
            input[type=radio]{background-image: url(../images/material/ic_radio_male.png);}
        }
        &.rfemale{
            input[type=radio]{background-image: url(../images/material/ic_radio_female.png);}
        }
    }
}
.checkbox-custom{
    position: relative; padding-left:20px; margin: 0; cursor: pointer;
    input[type=checkbox]{
        background: url(../images/material/ic_checkbox.png) no-repeat; display: inline-block; vertical-align: middle; margin:0; padding: 0; width:22px; height:22px; position:absolute; top:0; left:0; cursor: pointer;
        &:checked{background-position: right center;}
    }
}
a.link{
    &.u-b2{
        text-decoration: underline; font-weight: 700; color: #333; @include transition(0.2s color);
        &:hover{color: $blue;}
    }
}



/* btn-oval styles 
-----------------------------------------*/
.btn-oval{
    display: inline-block; background: $blue; color: #fff; @include borderRadius(50px); height: 50px; line-height: 50px; @include boxSizing(border-box); padding: 0 50px; margin: 0; cursor: pointer; border: 2px solid $blue; @include transition(0.2s background); @include transition(0.2s color); font-size: 14px;
    &:hover{color: $blue; background: #fff;}

    &.btn-submit{font-size: 18px; font-weight: 700;}
    &.btn-pink-large{
        font-size: 18px; font-weight: 700; background: $pink; border-color: $pink;
        &:hover{color: $pink; background: #fff;}
    }
    &.btn-grey-large{
        font-size: 18px; font-weight: 700; background: #999999; border-color: #999999;
        &:hover{color: #999999; background: #fff;}
    }
    &.btn-yellow{
        font-size: 16px; background: #ffc107; border-color: #ffc107; font-weight: 700; padding: 0 40px;
        &:hover{color: #ffc107;; background: #fff;}
    }
    &.abs { 
        position: absolute; top: -40px; right: 0;              
    }
}


/* end of btn-oval styles 
-----------------------------------------*/

/* row list styles 
-----------------------------------------*/
.row_list{
    margin-left: -15px; margin-right: -15px; @include boxSizing(border-box);
    *{@include boxSizing(border-box);}
    &:after{content: ""; display: table; clear: both;}
    .col{
        width: 100%; padding: 0 15px; float: left;
        &.fr{float: right;}
        &.no-pad{padding: 0;}
        &.marg28{margin-bottom: 28px;}
        &.col_9{width: 90%;}
        &.col_8{width: 80%;}
        &.col_7{width: 70%;}
        &.col_66{width: 66.66666666666666%;}
        &.col_65{width: 65%;}
        &.col_6{width: 60%;}
        &.col_55{width: 55%;}
        &.col_5{width: 50%;}
        &.col_45{width: 45%;}
        &.col_4{width: 40%;}
        &.col_37{width: 37%;}
        &.col_35{width: 35%;}
        &.col_33{width: 33.3333333333333%;}
        &.col_3{width: 30%;}
        &.col_25{width: 25%;}
        &.col_2{width: 20%;}
        &.col_16{width: 16.66666666666666%;}
        &.col_1{width: 10%;}
    }
    &.center{
        text-align: center;
        .col{float: none; display: inline-block;}
    }
    &.listfull{
        margin-left: 0; margin-right: 0;
        .col{padding: 0;}
    }
    &.listp5{
        margin-left: -5px; margin-right: -5px;
        .col{padding: 0 5px;}
    }
}
/* end row list styles 
-----------------------------------------*/

/* icons icwp 
-----------------------------------------*/
.icwp{
    width: 19px; height: 19px; background-repeat: no-repeat; background-position: top center; display: inline-block; position: relative;
    &:hover{background-position: bottom center;}

    &.ic_key{background-image: url('../images/material/ic_key.png'); width: 40px; height: 40px;}
    &.ic_user{background-image: url('../images/material/ic_user.png'); width: 18px; height: 21px;}
    &.ic_password{background-image: url('../images/material/ic_password.png'); width: 19px; height: 21px;}
    &.ic_user_white{background-image: url('../images/material/ic_user_white.png'); width: 22px; height: 23px;}
    &.ic_logout{background-image: url('../images/material/ic_logout.png'); width: 16px; height: 16px;}
}
/* end icons icwp 
-----------------------------------------*/


/* header
----------------------------------------------------------------------------------------------*/
header{
    .header-top{
        background: $blue; color: $white; text-align: center; padding: 10px 0; font-size: 16px; height: 50px; @include boxSizing(border-box); line-height: 32px; position: relative;
        a{
            color: $white;
            &:hover{color: #ffc107;}
            &.account_name{
                color: #ffc107; font-weight: 700;
                .icwp{margin-right: 10px;}
                /*&:hover{color: $white;}*/
            }
            &.logout_link{
                .icwp{margin-left: 10px;}
            }
        }
        .icwp{vertical-align: middle; position: relative;}
        .head-left{float: left;}
        .head-center{position: absolute; left: 50%; @include transform(translateX(-50%))}
        .head-right{float: right;}
    }
    .header-bottom{padding: 20px 0 35px;}
    .logo{float: left; width: 210px; max-width: 100%;}
    .nav-form-header{
        float: right; position: relative; margin-top: 55px;
        &:before{content: ""; height: 2px; background: #999; position: absolute; top: 50%; margin-top: -2px; left: 0; right: 0; z-index: -1;}
        li{
            padding: 0 20px; float: left;
            a{
                width: 16px; height: 16px; display: inline-block; border: 2px solid #999999; @include borderRadius(100%); @include boxSizing(border-box); background: #fff;
            }
            &:first-child{padding: 0 20px 0 0;}
            &:last-child{padding: 0 0 0 20px;}
            &.active{
                a{
                    border-color: $green; position: relative;
                    &:before{content: ""; position: absolute; top: 2px; left: 2px; bottom: 2px; right: 2px; background: $green; @include borderRadius(100%);}
                }
            }
        }
    }

    &.head-log{
        padding: 0;
        .wrapper{width: 1340px; margin: 0 auto;}
        .inner-head-log{
            padding: 45px 0 25px; border-bottom: 1px solid rgba(255,255,255,0.5);
            &:after{content: " ";clear: both; display: block;}
        }
    }
}


/* middle
----------------------------------------------------------------------------------------------*/
.bg-page{
    position: fixed; top: 0; left: 0; right: 0; bottom: 0; z-index: -1; background: url('../images/material/bg-full.jpg') no-repeat center; background-size: cover;
    &.teleuw {
        background: url('../images/material/bg-full-tele.jpg') no-repeat 0 0; 
    }
}
.box-login{
    position: relative;
    h1{font-size: 60px; font-weight: 300;}
    h2{font-size: 40px; font-weight: 300;}
    h5{font-size: 28px; font-weight: 300;}
    .wrapper{width: 1340px; margin: 0 auto; position: relative;}
    .text-left{
        color: #fff; width: 59.62%; float: left; min-height: 80vh; display: table;
        h1{margin-bottom: 40px;}
        .inner-text-left{display: table-cell; vertical-align: middle;}
        .btn-oval{margin-top: 70px;}
    }
    .box-right-log{
        position: absolute; top: 72px; right: 0;width: 35.07%; background: #ffffff; @include borderRadius(10px); overflow: hidden; padding: 70px 40px 70px; @include boxSizing(border-box);
        &:before{content: ""; position: absolute; top: 0; left: 0; right: 0; height: 10px; background: $yellow; @include borderRadius(10px 10px 0 0);}
        .box-form{
            label{
                position: relative; margin-bottom: 0; padding: 14px 0 10px 30px; color: #02356c;
                .icwp{position: absolute; top: 50%; left: 0; @include transform(translateY(-50%));}
            }
            input[type=text], input[type=password], input[type=email]{font-size: 18px; font-weight: 500; color: $blue;}
        }
        .box-log-bottom{
            text-align: center; margin-top: 30px;
            &:after{content: " ";clear: both; display: block;}
            a{
                font-size: 16px; color: #666666; text-decoration: underline; @include transition(0.2s all);
                &:hover{color: #02356c;}
            }
            .box-button{margin-bottom: 30px;}
        }
    }
    .title-log{
        position: relative; padding-left: 75px; margin-bottom: 62px;
        h2{color: #02356c;}
        .icwp{position: absolute; top: 0; left: 0;}
    }
}



.box-simulation{
    border: 1px solid #e6e6e6; @include boxSizing(border-box); position: relative; padding: 100px 0 80px; margin-bottom: 100px;
    &:before{content: ""; background: $yellow; height: 8px; border-bottom: 1px solid #e6e6e6; position: absolute; top: 0; left: 0; right: 0;}
}
.hline{
    position: relative;padding:0 0 42px;
    &:after{content: "";position: absolute; bottom: 0; left: 0; right: 0; margin: auto; height: 2px; width: 100px; background: $green;}
}
.box-form{
    font-size: 16px;
    h2{margin-bottom: 75px;}
    label{margin-bottom: 15px; display: block;}
    input, select, textarea{font-weight: 500;font-size: 16px;}
    input[type=text], input[type=email], input[type=password], input[type=number], select, textarea{
        @include borderRadius(10px); border: 1px solid #e6e6e6; height: 48px; @include boxSizing(border-box); width: 100%; padding: 0 15px;
        &:focus, &:active{border-color: #02356c;}
    }
    select{background: url('../images/material/arrow-sel.png') no-repeat right center; padding-right: 40px;}
    textarea{padding: 15px; height: 85px;}
    bdo{font-size: 18px; line-height: 26px; font-weight: 500; display: block; padding-bottom: 20px; border-bottom: 2px solid #e6e6e6;}
    .row_list{
        .col{
            margin-bottom: 30px;
            &.nomarg{margin-bottom: 0;}
        }
    }
}
.textstyle{
    &.ts-date{
        input{background-image: url('../images/material/ic_date.png'); background-repeat: no-repeat; background-position: left center;}
    }
    &.ts-left{
        input{padding-left: 50px; background-position: left center;}
    }
    &.ts-right{
        input{padding-right: 50px; background-position: right center;}
    }
    &.ts-search {
        input {
            background: url('../images/material/src_filt.png') no-repeat 15px center;  
        }
     }
    &.other {
        input {font-style: italic; font-weight: 300;}   
    }
}
.box-disclaimer{
    @include boxSizing(border-box); padding: 30px 38px; background: #ffefbf; font-size: 13px; line-height: 18px; @include borderRadius(10px); margin: 63px 0;
    h5{margin-bottom: 15px;}
}

.ui-datepicker{
    padding: 0;
    table{
        font-size: 12px;
        th{height: 30px; line-height: 30px;}
        thead{border-bottom: 1px solid #e6e6e6;}
    }
    .ui-datepicker-header{padding: 12px 10px 10px; background: $yellow; @include borderRadius(10px 10px 0 0);}
    .ui-datepicker-next-hover{background: transparent; border: none;}
    .ui-datepicker-prev, .ui-datepicker-next{
        top: 14px;
        span{background-image: url('../images/material/arrow-datepicker.png'); background-repeat: no-repeat; margin-left: -3px; margin-top: -4px;}
    }
    .ui-datepicker-prev{
        left: 25px;
        span{background-position: left center;}
    }
    .ui-datepicker-next{
        right: 25px;
        span{background-position: right center;}
    }
    .ui-datepicker-title{
        select{font-size: 12px; height: 30px; padding: 0 20px 0 10px; background: #fff url('../images/material/arrow-sel.png') no-repeat;background-position: 134% 50%;}
    }
}
.ui-widget{
    font-size: 16px;
    &.ui-widget-content{border: none; @include boxShadow(1px 6px 12px -2px rgba(0,0,0,0.2));}
}
.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active{
    border: none; background: transparent; height: 28px; line-height: 28px; position: relative; @include borderRadius(100%); text-align: center;
}
.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-button:active, .ui-button.ui-state-active:hover{
    background: #ff0335;
}
.ui-widget-header{
    .ui-icon{background-image: url('../images/material/arrow-datepicker.png'); width: 6px; height: 8px;}
}

.box-status-estimasi{
    font-size: 14px; line-height: 24px; color: #333; position: relative; @include borderRadius(10px); @include boxShadow(0 4px 26px -2px rgba(0,0,0,0.2)); margin-bottom: 65px;
    &:after{content: "";clear: both;display: block;}
    h5{font-weight: 400; margin-bottom: 20px;}
    .left-estimasi{
        color: #fff; position: absolute; left: 0; top: 0; bottom: 0; width: 27.71%; background: $blue;@include borderRadius(10px 0 0 10px);@include boxSizing(border-box); padding: 47px 30px;
        &.bg-blue-es{background: $blue;}
        &.bg-green-es{background: $green;}
    }
    .right-estimasi{width: 72.28%; float: right; @include boxSizing(border-box); padding: 75px 50px 75px 70px;}
}

.box-btm-next{
    font-size: 18px; font-weight: 500;
    &:after{content: "";clear: both; display: block;}
    .left{display: table; padding-left: 30px; max-width: 60%; @include boxSizing(border-box);}
    .right{max-width: 40%;}
    .mid{
        display: table; min-height: 50px;
        &:after{content: ""; display: block; clear: both;}
        .inner-mid{display: table-cell; vertical-align: middle;}
    }
}

.box-question{
    .list-question{
        border-bottom: 1px solid #e6e6e6; padding: 0 0 40px 100px; position: relative; @include boxSizing(border-box); margin-bottom: 40px;
        .box-number{
            position: absolute; top: 0; left: 0;width: 50px; height: 50px; @include boxSizing(border-box); text-align: center; display: table; @include borderRadius(100%); background: $yellow; color: #fff; font-weight: 700; font-size: 18px;
            span{display: table-cell; vertical-align: middle; padding-top: 3px;}
            &.num-pink{background: $pink;}
        }
        .box-quiz{
            font-size: 18px; line-height: 28px; color: #333; font-weight: 300;
            p{line-height: 28px; margin-bottom: 30px;}
            label{font-size: 16px; margin-bottom: 0; padding-top: 10px;}

            .box-choice-list{
                width: 100%; float: left; margin-top: 30px; display: none;
                .choice-list{
                    display: block; margin-bottom: 10px;
                    &:after{content: ""; clear: both; display: block;}
                    label{padding-top: 0; display: inline-block;}
                }
                .radio-custom{
                    padding-left: 30px; height: auto;
                    input[type=radio]{top: 7px;}
                }
                &.active{
                    display: block;
                }
            }
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
}

.slidetitle{
    position: relative; @include boxSizing(border-box);
    &:after{content: ""; clear: both; display: block;}
    *{@include boxSizing(border-box);}
    input{display: none;}
    .box-slidetitle{
        position: relative; border: 1px solid #acacac; @include borderRadius(50px); float: left; overflow: hidden; height: 50px;
        .list-slidetitle{
            position: relative; text-align: center; float: left; padding: 0 30px; height: 50px; line-height: 50px; @include borderRadius(50px); font-size: 16px; font-weight: 700; cursor: pointer; min-width: 110px; color: #666666;
            &:before{content: ""; position: absolute; top: 0; left: 100%; width: 100%; height: 100%; @include borderRadius(50px); @include transition(0.4s all); background: $blue; z-index: -1;}
            &:hover{
                color: #666666;
            }
            &.active{
                color: $white; font-weight: 500;
                &:before{left: 0;}
                &:hover{
                    color: $white;
                }
            }
            &:last-child{
                &:before{left: auto; right: 100%;}
                &:hover{
                    color: #666666;
                }
                &.active{
                    &:before{right: 0; left: auto;}
                    &:hover{
                        color: $white;
                    }
                }
            }
        }
    }
}

.box-dot-noted{margin-bottom: 60px; margin-top: 40px;}
.dot-noted{
    position: relative; padding-left: 30px; color: $pink; font-weight:300; font-size: 16px; display: inline-block;
    &:before{content: ""; position: absolute; top: -2px; left: 0; width: 16px; height: 16px; background: $pink; @include borderRadius(100%);}
}
.box-persetujuan{
    font-size: 16px;line-height: 24px; padding-left: 30px; position: relative;
    .box-checkbox-persetujuan{position: absolute; top: 0; left: 0; }
}
.box-updown{
    border-bottom: 1px solid #e6e6e6; padding: 0 0 30px; position: relative; margin-bottom: 50px;
    .box-text-updown{
        overflow: hidden; max-height: 75px; @include transition(0.6s linear max-height);
        &.active{max-height: 500px;}
    }
    .btn-scroll-updown{
        position: absolute; bottom: -13px; left: 0; right: 0; width: 26px; height: 26px; @include borderRadius(100%); background: #cccccc; margin: 0 auto; cursor: pointer; @include transition(0.2s background);
        &:before{content: ""; height: 2px; width: 10px; background: #fff; position: absolute; top: 50%; margin: -1px auto 0; left: 0; right: 0;}
        &:after{content: ""; height: 10px; width: 2px; background: #fff; position: absolute; top: 50%; margin: -5px auto 0; left: 0; right: 0;}
        &:hover{background: $blue;}
        &.active{
            &:after{opacity: 1; opacity: 0;}
        }
    }
}
.noted-text{
    font-size: 14px; line-height: 24px; font-weight: 500; color: #666;
    .text-pink{color: $pink;}
}

.popup{
    position: fixed; top: 0; left: 0; right: 0; bottom: 0; font-size: 16px; line-height: 24px; display: none;
    h2{margin-bottom: 50px;}
    a{font-weight: 700; color: $blue;}
    p{line-height: 24px;}
    .popup-overlay{position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0,0,0,0.5); z-index: 1;}
    .inner-popup{position: absolute; top: 20px; left: 0; right: 0; max-height: 70vh;  margin: 0 auto; background: $white; width: 1170px; max-width: 100%; z-index: 2; padding: 90px 0; @include borderRadius(10px); overflow: auto; @include transition(0.3s top);}
    .wrap-popup{width: 800px; margin: 0 auto;}

    &.show{
        display: block;
    }
}
.box-otp-code{margin: 12px auto 30px; width: 505px;}
.box-btn-otp{
    margin-bottom: 30px;
    .btn-oval{margin: 0 15px;}
}
.back_link{font-size: 16px; color: #ff0335; font-weight: 700; line-height: 50px;}








/* footer
----------------------------------------------------------------------------------------------*/



/* harlan css
----------------------------------------------------------------------------------------------*/


.wrap-inner {
    &.wrap_admin {width: 970px;}
    &.full {
        width: auto;
        .filter {
            margin: 0 30px 25px;
            &:after {
                clear: both; display: block; content: '';
            }
            .left {
                input[type="text"] {padding: 12px 20px 12px 50px !important;width: 350px;  background: url('../images/material/src_filt.png') no-repeat 5% center;}
                @include placeholder {color: #999;}
            }
            input[type="text"] {padding: 12px 20px !important; width: 250px;color: #999;}
            .datePick {
                background: url("../images/material/ic_date.png") no-repeat 98% center;
            }
        }
        .content_table {
            table {
                width: 100%;
                th,td {
                    border: 1px solid #d8d8d8; text-align: center; @include boxSizing(border-box); font-size: 16px;
                    &:first-child {border-left: none;}
                    &:last-child {border-right: none; width: 245px;}
                    &:nth-child(7) {width: 100px};
                }
                th {height: 70px; vertical-align: middle; padding: 10px; color: $blue;}
                td {
                    padding: 25px 10px;
                    a {
                        color: $blue; text-decoration: underline; display: inline-block; position: relative; margin: 0 5px;
                        &.round_upadte {
                            padding: 0 15px 0 35px; background: $blue; line-height: 30px; @include borderRadius(15px);
                            color: #fff; text-decoration: none; text-transform: uppercase; margin-bottom: 8px;
                            i{
                                display: inline-block; width: 16px; height: 16px; position: absolute; left: 10px; top: 5px;
                                background: url('../images/material/ico_detail.png') no-repeat center;
                            }
                        }
                    }
                }
            }
        }
    }
}

.ck_check {
    display: block; padding-left: 25px; font-size: 16px; color: #666; position: relative;
    input[type="checkbox"] {
        position: absolute; left: 0; top: 0; height: 16px; width: 16px; z-index: 2; padding: 0;
        background: url('../images/material/ico_checkbox.png') no-repeat top center; cursor: pointer; 
        &:checked {
            background-position: bottom center !important;
        }
    }
}


/* header
----------------------------------------------------------------------------------------------*/

header {
    .header-bottom {
        &.adm {
            .nav_mn {
                float: right; margin-top: 55px;
                a {
                    display: inline-block; font-size: 16px; color: $c_333; margin-left:38px; @include transition(color ease-out 0.25s);
                    &:hover,
                    &.active {
                         @include transition(color ease-out 0.25s); color: $blue;
                    }
                    &.active {font-weight: 700;}
                }
            }
        }
    }
}
.row_list {
    .col {
        > h3 {font-size: 20px; font-weight: 600; margin-bottom: 10px;}
        &.ck_block {
            .box-radio-button {
                padding-top: 32px;
                .radio-custom {
                    float: none; display: block; margin-bottom: 8px; height: auto;
                    &:last-child {margin-bottom: 0;}
                }
            }
        }
    }
    &.lsborder {
        border-bottom: 1px solid #ccc; margin-bottom: 40px;        
        .col {
            padding-left:55px; padding-right: 55px; position: relative;     
        }
        &.form {
            padding:0 35px 50px 35px; 
            .col {
                padding-left: 20px; padding-right: 20px;
            }
        }
        &.last {border-bottom: 0;} 
    }
}

.data {
    padding: 0 55px 30px; border-bottom: 1px solid #d8d8d8; margin-bottom: 35px;
    .col {
        margin-bottom: 10px !important;
        label {
            position: relative; float: left; margin-bottom: 0; width: 150px; font-size: 16px; line-height: 22px;
            &:after {
                content:':'; position: absolute; right: 0; top: 0px; 
            }
        }
        .txt {display: block; margin-left: 165px; font-size: 16px; line-height: 22px;}
        &:after{clear: both; content: ''; display: block;}
    }
    &:last-of-type {border-bottom: none; margin-bottom: 0;}
}
.data_form {
    padding: 0 55px 30px;
    .col {
        &:nth-of-type(1) {margin-bottom: 10px;} 
    }
}
.riwayat {
    .col {
        margin-bottom: 25px !important;
        label {
            display: block; float:  none; width: 100%; margin-bottom: 10px;
            &:after {display: none;}
        }
    }
    &.address {
        .col {margin-bottom: 0 !important;}
    }
}
.pagging {
    display: block; text-align: center; margin: 50px 0 -20px;
    a {
        display: inline-block; width: 22px; height: 22px; margin: 0 2px; @include borderRadius(50%); line-height: 24px; text-align: center;
        color: $c_666; @inclugde transition(all ease-out 0.25s);
        &.prev,
        &.next {
            width: auto;color: $c_999;@include borderRadius(0);
            &:hover {
                color: $c_333; background: none;
            }
        }
        &.prev {margin-right: 10px;}
        &.next {margin-left: 10px;}
        &:hover,
        &.active {
            background: $blue; color: #fff;@inclugde transition(all ease-out 0.25s);
        }
    }
}





/* css by andrisam 
============================================= */
.coltop {
    padding: 0 20px;
}

.table-std {
    position: relative;
    table {width: 100%;} 
    tr {
        th {
            padding: 25px 10px; border:1px solid #e7e7e7; color: #023770; font-weight: 500;    
            font-size: 16px; text-align: center; vertical-align: middle;     
            &:first-child {border-left:0;}  
            &:last-child {border-right:0;}     
            &:nth-child(1) {width: 9%;}
            &:nth-child(2) {width: 9%;}
            &:nth-child(3) {width: 9%;}
            &:nth-child(4) {width: 9%}
            &:nth-child(5) {width: 9%}
            &:nth-child(6) {width: 9%}
            &:nth-child(7) {width: 8%}
            &:nth-child(8) {width: 8%}
            &:nth-child(9) {width: 30%;}      
        }
        td {
            padding: 25px 10px; border:1px solid #e7e7e7; position: relative;  font-size: 13px; 
            text-align: center; vertical-align: middle;  
            &:first-child {border-left:0;} 
            &:last-child {
                border-right:0; vertical-align: top; 
            }   
            .act {color: #007858;} 
            .inact {color: #ff0335;}
            .new {
                position: absolute; width: 30px; height: 15px; background: #ffc107; color: #fff; font-size: 9px;  
                text-transform: uppercase; font-weight: bold; top: 0; right: 0; line-height: 17px;     
            } 
            .tb-btn {
                float: left; width: 115px; height: 30px; margin: 0 0 8px 8px; color: #023770;  
                text-decoration: underline; line-height: 32px; text-align: left; 
                @include borderRadius(20px); 
                @include boxSizing(border-box); 
                @include transition(all .15s ease-in-out); 
                &:last-child {margin-right:0;} 
                &.update { 
                    color: #fff; color: #fff; text-decoration: none; padding: 0 0 0 48px;  
                    background: #023770 url('../images/material/ic_update.png') no-repeat 18px center;
                    &:hover {
                        opacity:0.8;
                    }
                }
                &.close {                
                    color: #fff; color: #fff; text-decoration: none; padding: 0 0 0 48px;
                    background: #ff0335 url('../images/material/ic_close.png') no-repeat 18px center; 
                    &.c666 {
                        background: #666 url('../images/material/ic_close.png') no-repeat 18px center;
                    }
                    &:hover {
                        opacity:0.8; 
                    }
                }
                &.more {
                    width: auto;
                    &:hover {text-decoration:none;} 
                } 
            }
        }
        &.gray {
            td {background: #e5e5e5;}
        }
    }
}

.table-std2 { 
    position: relative; 
    table {
        width: 100%;
        td {
            padding: 3px; line-height: 18px;  
            &:first-child {color:#023770; width: 40%;}   
        }   
    }   
}


.btn-edit {
    font-size: 14px; color: #f39c12; padding: 0 0 0 27px;    
    background: url('../images/material/ic_edit.png') no-repeat 0 0;   
    &:hover {color:#f39c12;} 
} 

.table-std3 { 
    position: relative;
    table {
        width: 100%; border-collapse: separate; border-spacing: 0; border:1px solid #ccc;  
        @include borderRadius(6px);    
        th {
            padding: 20px 13.5px; background: #007858; color: #fff; text-align: left;              
            &:first-child {@include borderRadius(6px 0 0 0); padding-left:25px;}   
            &:last-child {@include borderRadius(0 6px 0 0); padding-right:25px;}    
        }
        tr {
            td { 
                padding: 15px 13.5px; line-height: 18px; 
                &:first-child {padding-left:25px;}
                &:last-child {padding-right:25px;}
            }  
            &:first-child {td{padding-top: 25px;}}   
            &:nth-child(odd) {
                td{background: #fff;}
            }            
            &:nth-child(even) {td{background: #f7f7f7;}}               
        }
    } 
}

